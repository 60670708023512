import AboutUs from "../../pages/aboutUs/aboutUs";
import ContactUs from "../../pages/contactUs/contactUs";
import Home from "../../pages/home/home";
import Products from "../../pages/products/products";
import Checkout from "../../pages/checkout/checkout";
import ProductDetail from "../../pages/productDetail/productDetail";
import AddProduct from "../../pages/addProduct/addProduct";
import Login from "../../pages/login/login";
import RemoveProduct from "../../pages/removeProduct/removeProduct";
import PrivacyPolicy from "../../pages/privacyPolicy/privacyPolicy";
import RefundPolicy from "../../pages/refundPolicy/refundPolicy";
import TermsOfUse from "../../pages/termsOfUse/termsOfUse";

export const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/products",
    component: Products,
  },
  {
    path: "/products/:id",
    component: ProductDetail,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/refund-policy",
    component: RefundPolicy,
  },
  {
    path: "/terms-of-use",
    component: TermsOfUse,
  },
  {
    path: "/admin/login",
    component: Login,
  },
  {
    path: "/admin/add-product",
    component: AddProduct,
    isProtected: true
  },
  {
    path: "/admin/remove-product",
    component: RemoveProduct,
    isProtected: true
  },
];
