import React from "react";
import "./sectionTitle.css";
import Text from "../text";
const SectionTitle = ({size, isTitle, isBold, className, children, style, color}) => {
  return (
    <>
    <div style={style}>
      <Text
        isTitle={isTitle}
        isBold={isBold}
        size={size}
        className={`section-title ${className}`}
        color={color}
      >
        {children}
      </Text>
    </div>
    </>
  );
};
export default SectionTitle;
