import React, { useState } from "react";
import styles from "./button.module.css";

const textStyle = (
  isTitle,
  size,
  color,
  backgroundColor,
  isBold,
  isSemibold,
  isUppercase,
  borderRadius,
  padding,
  isHovered, // New parameter for tracking hover state
  style
) => ({
  fontSize: size ? `${size}px` : "16px",
  color,
  backgroundColor,
  fontWeight: isBold ? "700" : isSemibold ? "400" : "normal",
  textTransform: isUppercase ? "uppercase" : "none",
  cursor: "pointer",
  border: "none",
  borderRadius: borderRadius ? borderRadius : "0.15rem",
  padding: padding ? padding : ".75rem .75rem",
  ...(isHovered && style.hover), // Apply hover styles if the button is hovered
  ...style,
});

const Button = ({
  isTitle,
  size,
  color,
  backgroundColor,
  isBold,
  isSemibold,
  isUppercase,
  className,
  borderRadius,
  padding,
  style,
  children,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const textStyles = textStyle(
    isTitle,
    size,
    color,
    backgroundColor,
    isBold,
    isTitle,
    isSemibold,
    isUppercase,
    borderRadius,
    padding,
    isHovered, // Pass the hover state to textStyle
    style
  );

  return (
    <button
      className={styles.buttonComp}
      type="button"
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true on mouse enter
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false on mouse leave
      {...rest}
      style={{ ...textStyles, ...style }}
    >
      {children}
    </button>
  );
};

export default Button;
