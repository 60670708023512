import React, { useState } from "react";
import styles from "./contentOverImageCards.module.css";
import { Box, Grid, Stack } from "@mui/material";
import Text from "../../Text/text";
import Button from "../../button/button";
import { useNavigate } from "react-router-dom";

const ContentOverImageCards = ({ data, buttonText }) => {
  const [showingProd, setShowingProd] = useState(() => 5);
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={4}>
        {data?.length &&
          data?.slice(0, showingProd).map((prod, index) => {
            return (
              <Grid
                item
                className={styles.image_content_card_main_div}
                key={index}
                xs={12}
                sm={6}
                lg={4}
              >
                <Box
                  className={styles.image_div}
                  style={{ backgroundImage: `url(${prod?.coverImage})` }}
                >
                  <Text
                    className={styles.image_div_text}
                    size={15}
                    color="white"
                    style={{ padding: "100px 0 3rem 0" }}
                  >
                    {prod?.subHeading && prod?.subHeading?.slice(0, 40)}
                  </Text>
                  <Text
                    size={21}
                    color="white"
                    isTitle
                    style={{ padding: "1rem 3rem" }}
                  >
                    {prod?.heading && prod?.heading.slice(0, 40)}
                  </Text>
                  <Stack direction="row" justifyContent="center">
                    <Button
                      style={{
                        width: "max-content",
                        fontSize: "11px",
                        padding: "1rem 2rem",
                      }}
                      onClick={() => navigate("/products")}
                    >
                      {buttonText}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
      </Grid>
      {data?.length > 8 && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ padding: "1.25rem 0" }}
        >
          <Button
            onClick={() =>
              setShowingProd(showingProd !== data?.length ? data?.length : 8)
            }
            backgroundColor="black"
            color="white"
            style={{ padding: "1rem 2rem" }}
          >
            {showingProd !== data?.length ? "Show More" : "Show Less"}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default ContentOverImageCards;
