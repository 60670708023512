import React, { useContext, useEffect } from "react";
import ContactButtonSlider from "../../components/slider/contactButtonSlider/contactButtonSlider";
import { mainSliderData } from "../../components/data/homeslider";
import { Box, CircularProgress, Container, Stack } from "@mui/material";
import SimpleCards from "../../components/cards/simpleCards/simpleCards";
import { SimpleCardsData } from "../../components/data/awais/simpleCards";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import { Context } from "../../components/context/context";
import styles from "../home/home.module.css";

const Products = () => {
  const { productData } = useContext(Context);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  return (
    <>
      <div className={styles.marquee}>
        <marquee behavior="scroll" direction="left" scrollamount="12">
          <SectionTitle size={15} color="red" isBold={true}>
            Winter collection is live now on sale! Enjoy free cash on delivery all over Pakistan.
          </SectionTitle>
        </marquee>
      </div>
      {/* <ContactButtonSlider
        dots={true}
        infinite={true}
        speed={5000}
        slidesToShow={1}
        slidesToScroll={1}
        data={mainSliderData}
      /> */}
      {productData?.length > 0 ? <Container sx={{ marginTop: "1.5rem" }}>
        <SectionTitle size={15}>NEWEST EDITION 24'</SectionTitle>
        <SectionTitle size={24}>LADIES SUMMER</SectionTitle>
        <SimpleCards data={productData} productPage={true} />
      </Container>:
      <Stack direction="row" justifyContent="center" sx={{padding: "2rem 0"}}>
      <CircularProgress style={{ height: "30px", width: "30px", color: "black" }} />
      </Stack>}
    </>
  );
};

export default Products;
