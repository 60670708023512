// In ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase";

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        navigate("/admin/login", { replace: true });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  if (!user) {
    return null;
  }

  return children;
};

export default ProtectedRoute;
