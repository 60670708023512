import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Grid, Container } from "@mui/material";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import { notify } from "../../components/notification/notification";
import Button from "../../components/button/button";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup.string().min(10, "Invalid phone number"),
  message: yup.string().required("Message is required"),
});

const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      notify("Message successfully submitted", "success");
      console.log(values);
    },
  });
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  return (
    <>
      <SectionTitle size={24} isBold style={{ margin: "2rem 0" }}>
        CONTACT US
      </SectionTitle>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <form onSubmit={formik.handleSubmit} style={{ maxWidth: "600px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                id="message"
                name="message"
                label="Message"
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="white"
                backgroundColor="black"
                type="submit"
                style={{ width: "100%" }}
              >
                SEND MESSAGE
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ContactUs;
