// Login.js
import React, { useState } from "react";
import { app } from "../../components/firebase/firebase";
import { notify } from "../../components/notification/notification";
import { useNavigate } from "react-router-dom";
import { auth } from "../../components/firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        notify("User logged in successfully!", "success");
        const user = userCredential.user;
        navigate("/admin/add-product");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        notify(`${errorMessage}`, "error");
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <div>{error}</div>}
      <form onSubmit={handleLogin}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
