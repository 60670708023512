import React, { useEffect } from "react";
import { Box, Container, Stack } from "@mui/material";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import Text from "../../components/Text/text";
import ownerImg from "../../assets/images/aboutus.jpg";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  return (
    <>
      <SectionTitle size={24}>ABOUT US</SectionTitle>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" alignItems="center" justifyContent="center" gap={5}>
            <Box sx={{ maxWidth: "600px" }}>
              <Text size={15}>
                Awais Fabric is a leading E-commerce store for ladies clothing.
                We offer best quality of multiple brands at extremely reasonable
                prices with FREE HOME DELIVERY and 6 days return/exchange
                policy.
              </Text>
            </Box>
            <img
              src={ownerImg}
              alt="Owner Picture"
              height="500px"
              style={{ maxWidth: "400px " }}
            />
            <Box sx={{ maxWidth: "600px" }}>
              <Text isBold>Message from the owner:</Text>
              <Text size={15}>
                The mission of Awais fabric and team is to provide quality
                clothing options to majority of our population in reasonable
                rates. The main purpose is to develop a trust factor that the
                customer will always receive quality products from us. Each
                dress is checked before dispatching, even then if, due to some
                human error the customer face any issue or problem in the
                product, we'll be happy to assist and resolve the issue.
              </Text>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default AboutUs;
