import React, { useContext, useState, useEffect } from "react";
import { doc, deleteDoc, getDoc, setDoc } from "firebase/firestore";
import { db, storage } from "../../components/firebase/firebase";
import { Context } from "../../components/context/context";
import AdminHeader from "../../components/header/adminHeader";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import { deleteObject, ref } from "firebase/storage";
import { notify } from "../../components/notification/notification";

const RemoveProduct = () => {
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [isCategory, setIsCategory] = useState("category");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryDocRef = doc(db, "Site", "category");
        const categoryDocSnap = await getDoc(categoryDocRef);
        const categoryData = categoryDocSnap.data();
        setCategoryData(categoryData?.dataArray);

        const productDocRef = doc(db, "Site", "product");
        const productDocSnap = await getDoc(productDocRef);
        const productData = productDocSnap.data();
        setProductData(productData?.dataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const deleteImageFromStorage = async (imageName) => {
    const imageRef = ref(storage, imageName);
    try {
      await deleteObject(imageRef);
      console.log("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image: ", error);
    }
  };
  const handleRemoveItem = async (itemId) => {
    try {
      if (isCategory === "category") {
        await deleteImageFromStorage(categoryData?.coverImage);
        await deleteDoc(doc(db, "Site", "category"));
        const siteDocRef = doc(db, "Site", "category");
        const remainingData = categoryData?.filter((item) => item?.heading !== itemId)
        await setDoc(
          siteDocRef,
          {
            dataArray: remainingData,
          }
        );
        setCategoryData(remainingData);
        notify("Delete item Successfully!", "success")
      } else {
        await deleteImageFromStorage(productData?.coverImage);
        productData?.images?.map(async(item)=>{
        await deleteImageFromStorage(item);
        })
        await deleteDoc(doc(db, "Site", "product"));
        const siteDocRef = doc(db, "Site", "product");
        const remainingData = productData?.filter((item) => item?.id !== itemId)
        await setDoc(
          siteDocRef,
          {
            dataArray: remainingData,
          }
        );
        setProductData(remainingData);
        notify("Delete item Successfully!", "success")
      }
    } catch (error) {
      console.error("Error removing item: ", error);
      notify("Error removing item!", "error")
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <AdminHeader isAddProduct={true} setFormType={setIsCategory} />
      <SectionTitle size={24} isTitle isBold>
        {isCategory}
      </SectionTitle>
      <table>
        <thead>
          <tr>
            {isCategory === "category" && <th>Heading</th>}
            {isCategory === "product" && <th>ID</th>}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {categoryData &&
            isCategory === "category" &&
            categoryData?.map((item) => {
              return (
                <tr>
                  <td>{item?.heading}</td>
                  <td>
                    <button onClick={() => handleRemoveItem(item?.heading)}>
                      Remove
                    </button>
                  </td>
                </tr>
              );
            })}
          {productData &&
            isCategory === "product" &&
            productData?.map((item) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>
                    <button onClick={() => handleRemoveItem(item?.id)}>
                      Remove
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default RemoveProduct;
