import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Grid,
  Container,
  List,
  Divider,
  ListItem,
  Stack,
  CircularProgress,
} from "@mui/material";
import { notify } from "../../components/notification/notification";
import { Context } from "../../components/context/context";
import Text from "../../components/Text/text";
import Button from "../../components/button/button";
import emailjs from "emailjs-com";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .min(10, "Invalid phone number"),
  postalCode: yup.string().required("Postal code is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
});

const Checkout = () => {
  const [totalBill, setTotalBill] = useState(() => 0);
  const [isLoading, setIsLoading] = useState(() => false)
  const { value, setValue } = useContext(Context);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      postalCode: "",
      address: "",
      city: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true)
      let orderData = [];
      const order = value?.map((item) => {
        orderData.push({
          designNo: item?.id,
          NOI: item?.NOI,
          price:
            Math.ceil((item?.price / 100) * (100 - parseInt(item?.discount))) *
            item?.NOI,
        });
      });
      const templateParams = {
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        postalCode: values.postalCode,
        address: values.address,
        city: values.city,
        country: values.country,
        orderDetail: JSON.stringify(orderData),
        totalAmount: totalBill,
      };
      emailjs
        .send(
          "service_gh0ucoh",
          "template_nbu3jgp",
          templateParams,
          "B-_MHJmR8K-ERLIcK"
        )
        .then(
          (response) => {
            notify("Order successfully submitted", "success");
            setIsLoading(false)
            values.name = "";
            values.email = "";
            values.phoneNumber = "";
            values.postalCode = "";
            values.address = "";
            values.city = "";
            values.country = "";
            localStorage.removeItem("cart");
            setValue([]);
          },
          (err) => {
            console.log("FAILED...", err);
            notify("Something went wrong!", "error");
            setIsLoading(false)
          }
        );
    },
  });
  useEffect(() => {
    let calc = 0;
    if (value && totalBill === 0) {
      value?.map((prod) => {
        calc +=
          Math.ceil((prod?.price / 100) * (100 - parseInt(prod?.discount))) *
          prod?.NOI;
      });
    }
    setTotalBill(calc);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "2rem 0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Text isBold size={18}>
                    Contact
                  </Text>
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text isBold size={18}>
                    Delivery
                  </Text>
                  <TextField
                    fullWidth
                    id="country"
                    name="country"
                    label="Country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    label="City"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    label="Postal Code"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.postalCode &&
                      Boolean(formik.errors.postalCode)
                    }
                    helperText={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    size={18}
                    isBold
                    style={{ padding: ".5rem 0 0.5rem 0" }}
                  >
                    Shipping Method
                  </Text>
                  <TextField
                    fullWidth
                    id="shippingMethod"
                    name="shippingMethod"
                    label="Shipping Method"
                    value="Free home delivery"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    size={24}
                    isBold
                    style={{ padding: ".5rem 0 0.5rem 0" }}
                  >
                    Payment
                  </Text>
                  <Text color="gray" size={11} style={{ padding: "0.5rem 0" }}>
                    All transactions are secure and encrypted.
                  </Text>
                  <TextField
                    fullWidth
                    id="payment"
                    name="payment"
                    label="Payment"
                    value="Cash on Delivery (COD)"
                    disabled
                  />
                  <Text color="gray" size={11} style={{ padding: "0.5rem 0" }}>
                    The parcel will be delivered within 2-3 days, the payment
                    method will be cash on delivery.
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="billingAddress"
                    name="billingAddress"
                    label="Billing Address"
                    value="Same as shipping address"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "0.4rem", cursor: isLoading ? "not-allowed" : "pointer" }}
                    backgroundColor="black"
                    color="white"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading && (<CircularProgress style={{ height: "15px", width: "15px", color: "white" }} />)}
                    Complete Order
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <List sx={{ height: "75vh", overflowY: "auto" }}>
              <Divider />
              {value?.map((item, index) => {
                return (
                  <ListItem key={index} disablePadding>
                    <Grid container spacing={{ xs: 5, md: 2 }}>
                      <Grid item xs={5}>
                        <img
                          src={item?.coverImage}
                          height="100px"
                          alt={item?.alt}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <Stack
                          direction="column"
                          justifyContent="space-around"
                          sx={{ height: "100%" }}
                        >
                          <Text>Design No. {item?.id}</Text>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Stack>
                                <Text color="gray">
                                  RS.{" "}
                                  {Math.ceil(
                                    (item?.price / 100) *
                                    (100 - parseInt(item?.discount))
                                  ) * item?.NOI}
                                </Text>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            ></Grid>
                          </Grid>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
            <Button
              style={{ width: "100%", cursor: "not-allowed" }}
              backgroundColor="black"
              color="white"
            >
              Total Amount: {totalBill}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Checkout;
