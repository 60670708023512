import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { SimpleCardsData } from "../../components/data/awais/simpleCards";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import Text from "../../components/Text/text";
import Button from "../../components/button/button";
import { Context } from "../../components/context/context";
import { notify } from "../../components/notification/notification";
import SimpleCards from "../../components/cards/simpleCards/simpleCards";

const ProductDetail = () => {
  const [numberOfItems, setNumberOfItems] = useState(() => 1);
  const { id } = useParams();
  const { productData } = useContext(Context);
  const { value, setValue } = useContext(Context);
  const prevCartData = JSON.parse(localStorage.getItem("cart"));
  const filteredData =
    productData.length > 0 &&
    productData?.find((prod) => {
      return prod?.id === id;
    });
  const relatedProdItems =
    productData.length > 0 &&
    productData?.filter((prod) => {
      return prod?.fabric === filteredData?.fabric;
    });
  const [imageChanger, setImageChanger] = useState(filteredData?.coverImage);
  useEffect(() => {
    setImageChanger(filteredData?.coverImage);
  }, [filteredData]);
  const UpdateCart = () => {
    const alreadyInCart = value?.find((prod) => {
      return prod?.id === filteredData.id;
    });
    if (value?.length) {
      if (alreadyInCart) {
        notify("Item already in cart!", "error");
      } else {
        localStorage.setItem(
          "cart",
          JSON.stringify([
            ...value,
            {
              ...filteredData,
              NOI: numberOfItems,
            },
          ])
        );
        setValue([
          ...value,
          {
            ...filteredData,
            NOI: numberOfItems,
          },
        ]);
        notify("Item added successfully in cart!", "success");
      }
    } else {
      localStorage.setItem(
        "cart",
        JSON.stringify([
          {
            ...filteredData,
            NOI: numberOfItems,
          },
        ])
      );
      setValue([
        {
          ...filteredData,
          NOI: numberOfItems,
        },
      ]);
      notify("Item added successfully in cart!", "success");
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Stack direction="column" gap="1rem" sx={{ height: "100%" }}>
              <img
                src={imageChanger}
                alt={filteredData?.alt}
                height="100%"
                width="100%"
                style={{ position: "center" }}
              />
              <Stack direction="row" gap="1rem">
                {filteredData?.images?.map((image, index) => {
                  console.log(".length", image);
                  return (
                    <Box
                      sx={{
                        border: "1px solid black",
                        borderRadius: "1rem",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onMouseOver={() => setImageChanger(image)}
                      onClick={() => setImageChanger(image)}
                      key={index}
                    >
                      <img
                        src={image}
                        alt=""
                        height="50qpx"
                        width="50px"
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{ padding: "2rem" }}>
              <SectionTitle size={15}>AWAIS FABRIC</SectionTitle>
              <SectionTitle size={22}>{filteredData?.title}</SectionTitle>
              <Stack direction="row" alignItems="center" gap="1.25rem">
                {filteredData?.discount && (
                  <Text color="red" size={18}>
                    RS.{" "}
                    {Math.ceil(
                      (filteredData?.price / 100) *
                        (100 - parseInt(filteredData?.discount))
                    )}
                  </Text>
                )}
                <Text
                  size={14}
                  color="grey"
                  style={{ textDecoration: "line-through" }}
                >
                  RS. {filteredData?.price}
                </Text>
              </Stack>
              <hr />
              <Stack sx={{ margin: "1.25rem 0", lineHeight: "1.5rem" }}>
                <Text isTitle isBold>
                  Product details:
                </Text>

                <Text size={15}>
                  {filteredData?.fabric && `Fabric: ${filteredData?.fabric}`}
                </Text>
                <Text size={15}>
                  {filteredData?.shirt &&
                    `Shirt (2.5m): ${filteredData?.shirt}`}
                </Text>
                <Text size={15}>
                  {filteredData?.frontBack &&
                    `Front + Back: ${filteredData?.frontBack}`}
                </Text>
                <Text size={15}>
                  {filteredData?.sleeves &&
                    `Sleeves (0.25m): ${filteredData?.sleeves}`}
                </Text>
                <Text size={15}>
                  {filteredData?.duppata &&
                    `Duppata (2.25m): ${filteredData?.duppata}`}
                </Text>
                <Text size={15}>
                  {filteredData?.trouser &&
                    `Trouser (2.25m): ${filteredData?.trouser}`}
                </Text>
                {filteredData?.patch && (
                  <Text>
                    Patch:{" "}
                    <ul>
                      {filteredData?.patch?.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </Text>
                )}
              </Stack>
              <Text isBold isTitle size={18}>
                UNSTICHED
              </Text>
              <Stack direction="column" gap="0.75rem" sx={{ margin: "1rem 0" }}>
                <Text isBold style={{ lineHeight: "1.5rem" }}>
                  Note : The colour of the actuall product can be slightly
                  different due to camera effects and lighting conditions
                </Text>
                <Text isBold style={{ lineHeight: "1.5rem" }}>
                  P.S : Its our own inspiration of the design, hence kindly see
                  open pictures and description of the actual product uploaded
                  on the website while ordering
                </Text>
                <Text isBold style={{ lineHeight: "1.5rem" }}>
                  We offer 7 days return/exchange policy
                </Text>
                <Stack
                  direction="row"
                  sx={{
                    border: "1px solid black",
                    width: "100px",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "2rem",
                  }}
                >
                  <Text
                    size={24}
                    style={{
                      cursor: numberOfItems > 1 ? "pointer" : "not-allowed",
                      pointerEvents: numberOfItems <= 1 && "none",
                    }}
                    onClick={() =>
                      setNumberOfItems(numberOfItems > 1 && numberOfItems - 1)
                    }
                  >
                    -
                  </Text>
                  <Box
                    sx={{
                      border: "1px solid black",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 .5rem",
                    }}
                  >
                    <Text>{numberOfItems}</Text>
                  </Box>
                  <Text
                    size={24}
                    onClick={() => setNumberOfItems(numberOfItems + 1)}
                    style={{ cursor: "pointer" }}
                  >
                    +
                  </Text>
                </Stack>
              </Stack>
              <Button
                backgroundColor="black"
                color="white"
                size={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  width: "100%",
                }}
                onClick={UpdateCart}
              >
                ADD TO CART
                <Text color="white">|</Text>
                {filteredData?.discount && (
                  <Text color="white" size={12}>
                    RS.{" "}
                    {Math.ceil(
                      (filteredData?.price / 100) *
                        (100 - parseInt(filteredData?.discount))
                    ) * numberOfItems}
                  </Text>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {!!relatedProdItems && (
          <>
            <SectionTitle size={24}>RELATED PRODUCTS</SectionTitle>
            <SimpleCards data={relatedProdItems} />
          </>
        )}
      </Container>
    </>
  );
};

export default ProductDetail;
