import { doc, getDoc } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { db } from "../firebase/firebase";

export const Context = createContext("Default Value");

export const MyProvider = ({ children }) => {
  const [value, setValue] = useState(JSON.parse(localStorage.getItem("cart")) || []);
  const [categoryData, setCategoryData] = useState({});
  const [productData, setProductData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryDocRef = doc(db, "Site", "category");
        const categoryDocSnap = await getDoc(categoryDocRef);
        const categoryData = categoryDocSnap.data();
        setCategoryData(categoryData?.dataArray);

        const productDocRef = doc(db, "Site", "product");
        const productDocSnap = await getDoc(productDocRef);
        const productData = productDocSnap.data();
        setProductData(productData?.dataArray);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Context.Provider value={{ value, setValue, categoryData, productData, isLoading }}>
      {children}
    </Context.Provider>
  );
};
