import React, { useContext, useEffect } from "react";
import styles from "./home.module.css";
import ContactButtonSlider from "../../components/slider/contactButtonSlider/contactButtonSlider";
import { mainSliderData } from "../../components/data/homeslider";
import { Box, CircularProgress, Container, Stack } from "@mui/material";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import SimpleCards from "../../components/cards/simpleCards/simpleCards";
import { SimpleCardsData } from "../../components/data/awais/simpleCards";
import ContentOverImageCards from "../../components/cards/contentOverImageCards/contentOverImageCards";
import { contentOverImageCardsData } from "../../components/data/awais/contentOverImageCards";
import { Context } from "../../components/context/context";
const Home = () => {
  const { categoryData, productData } = useContext(Context);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className={styles.marquee}>
        <marquee behavior="scroll" direction="left" scrollamount="12">
          <SectionTitle size={15} color="red" isBold={true}>
            Winter collection is live now on sale! Enjoy free cash on delivery all over Pakistan.
          </SectionTitle>
        </marquee>
      </div>
      {/* <ContactButtonSlider
        dots={true}
        infinite={true}
        speed={5000}
        slidesToShow={1}
        slidesToScroll={1}
        data={mainSliderData}
      /> */}
      {productData?.length > 0 || categoryData?.length > 0 ? (
        <Container sx={{ marginTop: "1.5rem" }}>
          {productData?.length > 0 && (
            <>
              <SectionTitle size={15}>NEWEST EDITION 2024-25'</SectionTitle>
              <SectionTitle size={24}>LADIES WINTER COLLECTION</SectionTitle>
              <SimpleCards data={productData} />
            </>
          )}
          <Box sx={{ margin: "2rem 0" }}>
            {categoryData?.length > 0 && (
              <ContentOverImageCards
                data={categoryData}
                buttonText="VIEW PRODUCTS"
              />
            )}
          </Box>
          {productData?.length > 0 && (
            <>
              <SectionTitle size={15}>TOP PICKS OF THE WEEK</SectionTitle>
              <SectionTitle size={24}>BEST SELLING ARTICLES</SectionTitle>
              <SimpleCards data={productData?.reverse()?.slice(0, 4)} />
            </>
          )}
        </Container>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ padding: "2rem 0" }}
        >
          <CircularProgress
            style={{ height: "30px", width: "30px", color: "black" }}
          />
        </Stack>
      )}
    </>
  );
};

export default Home;
