import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import { CircularProgress, Grid } from "@mui/material";
import { auth, db, storage } from "../../components/firebase/firebase";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { notify } from "../../components/notification/notification";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import firebase from "firebase/compat/app";
import AdminHeader from "../../components/header/adminHeader";

const errorMsg = {
  color: "red",
};
const categorySchema = Yup.object().shape({
  coverImage: Yup.mixed().required("Image is required"),
  alt: Yup.string().required("Alternative of image text is required"),
  heading: Yup.string().required("Heading is required"),
  subHeading: Yup.string().required("Sub heading is required"),
});
const productSchema = Yup.object().shape({
  id: Yup.number().required("ID is required"),
  coverImage: Yup.mixed().required("Cover image is required"),
  alt: Yup.string().required("Alternative of image text is required"),
  images: Yup.array()
    .of(Yup.mixed())
    .required("At least one image is required"),
  title: Yup.string().required("Title is required"),
  category: Yup.string().required("Category is required"),
  description: Yup.string().required("Description is required"),
  price: Yup.number().required("Price is required"),
  discount: Yup.string(),
  fabric: Yup.string().required("Fabric is required"),
  shirt: Yup.string(),
  frontBack: Yup.string(),
  sleeves: Yup.string(),
  duppata: Yup.string(),
  trouser: Yup.string(),
});

const AddProduct = () => {
  const [formType, setFormType] = useState("category");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues =
    formType === "category"
      ? {
          heading: "",
          subHeading: "",
          coverImage: undefined,
          alt: "",
        }
      : {
          heading: "",
          subHeading: "",
          id: "",
          coverImage: undefined,
          alt: "",
          images: [],
          title: "",
          category: "",
          description: "",
          price: "",
          discount: "",
          fabric: "",
          shirt: "",
          frontBack: "",
          sleeves: "",
          duppata: "",
          trouser: "",
        };

  const handleFileChange = (setFieldValue, fieldName) => (event) => {
    const files = event.target.files;
    setFieldValue(
      fieldName,
      fieldName !== "coverImage" ? Array.from(files) : files
    );
  };

  const uploadFile = async (values, collectionId, documentId, actions) => {
    const urls = [];
    let URL;
    const imageRef = ref(storage, values.coverImage[0].name);

    uploadBytes(imageRef, values.coverImage[0])
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then(async (url) => {
            if (documentId === "category") {
              const data = {
                ...values,
                coverImage: url,
              };
              await handleFormSubmission(
                data,
                collectionId,
                documentId,
                actions
              );
            } else {
              URL = url;
            }
          })
          .catch((error) => {
            setIsLoading(false);
            notify(error.message, "error");
          });
      })
      .catch((error) => {
        setIsLoading(false);
        notify(error.message, "error");
      });
    if (documentId === "product") {
      values?.images?.map((img, index) => {
        const imageRef = ref(storage, img?.name ? img?.name : String(index));
        uploadBytes(imageRef, img)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then(async (url) => {
                if (values?.images?.length === index + 1) {
                  urls.push(url);
                  const data = {
                    ...values,
                    images: urls,
                    coverImage: URL,
                  };
                  await handleFormSubmission(
                    data,
                    collectionId,
                    documentId,
                    actions
                  );
                } else {
                  urls.push(url);
                }
              })
              .catch((error) => {
                setIsLoading(false);
                notify(error.message, "error");
              });
          })
          .catch((error) => {
            setIsLoading(false);
            notify(error.message, "error");
          });
      });
    }
  };
  const handleFormSubmission = async (
    data,
    collectionId,
    documentId,
    actions
  ) => {
    const siteDocRef = doc(db, collectionId, documentId);
    try {
      const docSnap = await getDoc(siteDocRef);
      if (docSnap.exists()) {
        await updateDoc(siteDocRef, {
          dataArray: arrayUnion(data),
        });
      } else {
        await setDoc(
          siteDocRef,
          {
            dataArray: [data],
          }
        );
      }
      // await setDoc(siteDocRef, data, { merge: true });
      setIsLoading(false);
      notify("Added data successfully!", "success");
      actions.setSubmitting(false);
      // setFieldValue("heading", "");
      // setFieldValue("subHeading", "");
      // setFieldValue("coverImage", undefined);
      // setFieldValue("alt", "");
      // setFieldValue("id", "");
      // setFieldValue("images", []);
      // setFieldValue("title", "");
      // setFieldValue("category", "");
      // setFieldValue("description", "");
      // setFieldValue("price", "");
      // setFieldValue("discount", "");
      // setFieldValue("fabric", "");
      // setFieldValue("shirt", "");
      // setFieldValue("frontBack", "");
      // setFieldValue("sleeves", "");
      // setFieldValue("duppata", "");
      // setFieldValue("trouser", "");
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      notify("Error on form submitting!", "error");
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <AdminHeader isAddProduct={true} setFormType={setFormType} />

      <Formik
        initialValues={initialValues}
        validationSchema={
          formType === "category" ? categorySchema : productSchema
        }
        onSubmit={async (values, actions) => {
          try {
            setIsLoading(true);
            const collectionId = "Site";
            const documentId = values?.id ? "product" : "category";
            await uploadFile(values, collectionId, documentId, actions);
          } catch (error) {
            setIsLoading(false);
            console.log("error", error);
            notify("Error on form submitting!", "error");
          }
        }}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <SectionTitle isBold size={24}>
              {formType}
            </SectionTitle>
            {formType === "category" ? (
              <>
                <Grid Container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      type="file"
                      name="coverImage"
                      id="coverImage"
                      value={undefined}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleFileChange(setFieldValue, "coverImage")}
                    />
                    <ErrorMessage
                      style={errorMsg}
                      name="image"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="alt" placeholder="Alt text" />
                    <ErrorMessage style={errorMsg} name="alt" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="heading" placeholder="Heading" />
                    <ErrorMessage
                      style={errorMsg}
                      name="heading"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="subHeading"
                      placeholder="Sub Heading"
                    />
                    <ErrorMessage
                      style={errorMsg}
                      name="subHeading"
                      component="div"
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid Container spacing={3}>
                  <Grid item xs={12}>
                    <Field type="text" name="id" placeholder="ID" />
                    <ErrorMessage style={errorMsg} name="id" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="file"
                      name="coverImage"
                      id="coverImage"
                      value={undefined}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleFileChange(setFieldValue, "coverImage")}
                    />
                    <ErrorMessage
                      style={errorMsg}
                      name="coverImage"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="alt" placeholder="Alt text" />
                    <ErrorMessage style={errorMsg} name="alt" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="file"
                      name="images"
                      value={undefined}
                      accept="image/png, application/pdf, image/jpeg, image/jpg"
                      multiple
                      onChange={handleFileChange(setFieldValue, "images")}
                    />
                    <ErrorMessage
                      style={errorMsg}
                      name="images"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="title" placeholder="Title" />
                    <ErrorMessage
                      style={errorMsg}
                      name="title"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="category" placeholder="Category" />
                    <ErrorMessage
                      style={errorMsg}
                      name="category"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as="textarea"
                      name="description"
                      placeholder="Description"
                    />
                    <ErrorMessage
                      style={errorMsg}
                      name="description"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="number" name="price" placeholder="Price" />
                    <ErrorMessage
                      style={errorMsg}
                      name="price"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="number"
                      name="discount"
                      placeholder="Discount"
                    />
                    <ErrorMessage
                      style={errorMsg}
                      name="discount"
                      component="div"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="fabric" placeholder="Fabric" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="shirt" placeholder="Shirt" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="frontBack"
                      placeholder="frontBack"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="sleeves" placeholder="sleeves" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="duppata" placeholder="duppata" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field type="text" name="trouser" placeholder="trouser" />
                  </Grid>
                </Grid>
              </>
            )}

            <button type="submit" disabled={isLoading}>
              {isLoading && (
                <CircularProgress
                  style={{ height: "10px", width: "10px", color: "black" }}
                />
              )}
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddProduct;
