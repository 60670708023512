import React from "react";
import PropTypes from "prop-types";
import "./text.css";

const textStyle = (
  isTitle,
  size,
  color,
  isBold,
  isSemibold,
  isUppercase,
  style
) => ({
  fontSize: size ? `${size}px` : "16px",
  color,
  fontWeight: isBold ? "700" : isSemibold ? "400" : "normal",
  textTransform: isUppercase ? "uppercase" : "none",
  backgroundColor: "transparent",
  ...style,
});

const Text = ({
  children,
  isTitle,
  size,
  color,
  isBold,
  isSemibold,
  isUppercase,
  className,
  style,
  ...rest
}) => {
  const textStyles = textStyle(
    isTitle,
    size,
    color,
    isBold,
    isTitle,
    isSemibold,
    isUppercase,
    style
  );
  return (
    <div className={className} {...rest} style={{ ...textStyles, ...style }}>
      {children}
    </div>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  isTitle: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  isBold: PropTypes.bool,
  isSemibold: PropTypes.bool,
  isUppercase: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
};

Text.defaultProps = {
  children: "",
  isTitle: false,
  size: 16,
  color: "black",
  isSemibold: false,
  isBold: false,
  isUppercase: false,
  className: "",
  style: {},
};

export default Text;
