import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBFeBCmyHDNRyKf5Rsmff63yXUeXl1xtls",
  authDomain: "awaisfabrics-22031.firebaseapp.com",
  projectId: "awaisfabrics-22031",
  storageBucket: "awaisfabrics-22031.appspot.com",
  messagingSenderId: "938326956015",
  appId: "1:938326956015:web:7ec6eb8b36a71f6e0dc81a",
  measurementId: "G-E3KRWLZEFS"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);