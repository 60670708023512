import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { Route, Routes } from "react-router-dom";
import { routes } from "./components/routes/routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Context } from "./components/context/context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import whatsappLogo from "../src/assets/images/whatsapp.png";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ProtectedRoute from "./components/protectedRoute/protectedRoute";
import CustomLoader from "./components/customLoader/customLoader";

function App() {
  const { value, setValue, productData, isLoading } = useContext(Context);
  const [authenticated, setAuthenticated] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });

    if (
      !value &&
      JSON.parse(localStorage.getItem("cart")) &&
      typeof setValue === "function"
    ) {
      setValue(JSON.parse(localStorage.getItem("cart")));
    }

    return () => unsubscribe();
  }, [value, setValue, auth, isLoading]);

  if (isLoading) {
    return (
      <div>
        <CustomLoader text="Awais Fabric..." />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Routes>
        {routes.map((route) => {
          if (route?.isProtected) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute authenticated={authenticated}>
                    <route.component />
                  </ProtectedRoute>
                }
              />
            );
          }
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          );
        })}
      </Routes>
      <div
        style={{
          position: "fixed",
          bottom: "16px",
          right: "16px",
          zIndex: 1000000,
        }}
      >
        <a href="https://wa.me/+923117101196">
          <img src={whatsappLogo} height="48px" width="48px" alt="WhatsApp" />
        </a>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
