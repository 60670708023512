import React, { useEffect } from "react";
import styles from "./refundPolicy.module.css";
import { Container } from "@mui/material";
import SectionTitle from "../../components/Text/sectionTitle/sectionTitle";
import Text from "../../components/Text/text";

const textStyle = {
  fontSize: "14px",
  lineHeight: "20px",
};

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Container className={styles.refund_policy_main_div}>
        <SectionTitle isBold style={{ textDecoration: "underline" }}>
          Refund policy
        </SectionTitle>
        <Text style={textStyle}>
          We have a 7-day smooth and hassle-free return/exchange policy, which
          means you have 7 days after receiving your item to request a
          return/exchange.
        </Text>
        <Text style={textStyle}>
          To be eligible for a return, your item must be in the same condition
          that you received it, unworn or unused, with tags, and in its original
          packaging. You’ll also need the receipt or proof of purchase.
        </Text>
        <Text style={textStyle}>
          To start a return, you can contact us at our WhatsApp number
          (+923117101196). If your return is accepted, we’ll send you a return
          shipping address, as well as instructions on how and where to send
          your package. Items sent back to us without first requesting a return
          will not be accepted.
        </Text>
        <Text style={textStyle}>
          You can always contact us for any return questions at our WhatsApp
          number (+923117101196)
        </Text>
        <Text isBold>1: In case of change of mind by the customer:</Text>
        <Text style={textStyle}>
          • Customer will return the product him/herself to us via courier or
          any other means. Once the product reaches our office, only then a
          refund or replacement will be processed. All the relavant information
          regarding the parcel such as sender name, courrier company, tracking
          number, and courrier should be clearly mentioned from customers side
          so we can keep track of it.
        </Text>
        <Text style={textStyle}>
          <b>• Refund:</b> Payment will be transferred after standard cod
          deduction rules
        </Text>
        <Text style={textStyle}>
          -: Single product 15% deduction on the overall bill
        </Text>
        <Text style={textStyle}>
          -: Multiple products 10% deduction on the overall bill
        </Text>
        <Text style={textStyle}>
          <b>• Replacement:</b> The replacement will be shipped after clearing
          the price difference, if the replacement order is less of the original
          product we'll transfer the difference, if its more the extra amount
          will be cod{" "}
        </Text>
        <Text isBold>2: In case of faulted product:</Text>
        <Text style={textStyle}>
          • In case of minor faults, if the customer agrees, we'll offer the
          customer an appriopriate discount
        </Text>
        <Text style={textStyle}>
          • If the fault/damage is such that product is not useable we'll
          replace the order completely free of charge.
        </Text>
        <Text style={textStyle}>
          -: If we request the customer to send the parcel back, we'll share the
          delivery charges in customer account (post office 200/-)
        </Text>
        <Text style={textStyle}>
          -: If reverse pickup is initiated by us, then fresh product will be
          dispatched after pickup is processed{" "}
        </Text>
        <Text style={textStyle}>
          • If the customer wishes for a refund, we will pay the return delivery
          cost (post office) and after standard cod deduction rules payment will
          be refunded.
        </Text>
        <Text isBold>Damages and issues</Text>
        <Text style={textStyle}>
          Please inspect your order upon reception and contact us immediately if
          the item is defective, damaged or if you receive the wrong item, so
          that we can evaluate the issue and make it right.
        </Text>
        <Text isBold>Exchanges</Text>
        <Text style={textStyle}>
          The fastest way to ensure you get what you want is to return the item
          you have, and once the return is accepted, make a separate purchase
          for the new item.
        </Text>
        <Text isBold>Refunds</Text>
        <Text style={textStyle}>
          We will notify you once we’ve received and inspected your return, and
          let you know if the refund was approved or not. If approved, you’ll be
          automatically refunded on your original payment method. Please
          remember it can take some time for your bank or credit card company to
          process and post the refund too.
        </Text>
      </Container>
    </>
  );
};

export default RefundPolicy;
