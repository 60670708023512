import { Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import Text from "../../Text/text";
import styles from "./simpleCards.module.css";
import Button from "../../button/button";
import { Link } from "react-router-dom";

const SimpleCards = ({ data, style, imgHeight, imgWidth, productPage }) => {
  const [showingProd, setShowingProd] = useState(() => 8);
  console.log(".length hh", data);
  
  return (
    <>
      <Grid container spacing={4}>
        {data?.length > 0 &&
          data
            ?.slice(0, productPage ? data?.length : showingProd)
            ?.map((prod, index) => {
              console.log(".length hh", prod?.coverImage);
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: "1.25rem" }}
                >
                  <Link
                    to={`/products/${prod?.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box className={styles.coverimage_container}>
                      <img className={styles.coverimage} src={prod?.coverImage} alt={prod?.alt}/>
                      {prod?.category && (
                        <>
                          <Text
                            size={14}
                            style={{
                              backgroundColor: "red",
                              width: "max-content",
                              padding: ".15rem .3rem",
                              borderRadius: ".15rem",
                              position: "absolute",
                              top: "3%",
                              left: "3%",
                              color: "white",
                            }}
                          >
                            - {prod?.discount}%
                          </Text>
                          <Text
                            size={14}
                            style={{
                              backgroundColor: "white",
                              width: "max-content",
                              padding: ".15rem .3rem",
                              borderRadius: ".15rem",
                              position: "absolute",
                              top: "12%",
                              left: "3%"
                            }}
                          >
                            {prod?.category}
                          </Text>
                        
                        </>
                        )}
                      {/* <Box
                        // className={styles.coverimage}
                        sx={{ backgroundImage: `url(${prod?.coverImage})`, height: "200px" }}
                      >
                        {prod?.category && (
                          <Text
                            size={14}
                            style={{
                              backgroundColor: "white",
                              width: "max-content",
                              padding: ".15rem .3rem",
                              borderRadius: ".15rem",
                            }}
                          >
                            {prod?.category}
                          </Text>
                        )}
                      </Box> */}
                    </Box>
                    <Text style={{ margin: "1.25rem 0 0.5rem 0" }}>
                      {prod?.description.slice(0, 60)}
                      {prod?.description.length > 60 && "..."}
                    </Text>
                    <Stack direction="row" gap="1.25rem">
                      {prod?.discount && (
                        <Text color="red">
                          RS.{" "}
                          {Math.ceil(
                            (prod?.price / 100) *
                              (100 - parseInt(prod?.discount))
                          )}
                        </Text>
                      )}
                      <Text
                        size={14}
                        color="grey"
                        style={{ textDecoration: "line-through" }}
                      >
                        RS. {prod?.price}
                      </Text>
                    </Stack>
                  </Link>
                </Grid>
              );
            })}
      </Grid>
      {data?.length > 8 && !productPage && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ padding: "1.25rem 0" }}
        >
          <Button
            onClick={() =>
              setShowingProd(showingProd !== data?.length ? data?.length : 8)
            }
            backgroundColor="black"
            color="white"
            style={{ padding: "1rem 2rem" }}
          >
            {showingProd !== data?.length ? "Show More" : "Show Less"}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default SimpleCards;
