import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(360deg); }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Spinner = styled.div`
  animation: ${spin} 5s linear infinite;
`;

const LoaderText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: black;
`;

const CustomLoader = ({ text }) => {
  return (
    <LoaderContainer>
      <Spinner>
        <LoaderText>{text}</LoaderText>
      </Spinner>
    </LoaderContainer>
  );
};
export default CustomLoader;
