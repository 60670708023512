import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "../button/button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from '@mui/icons-material/Delete';
import { Close, ShoppingCartOutlined } from "@mui/icons-material";
import { Badge, Grid, IconButton, Stack, styled } from "@mui/material";
import Text from "../Text/text";
import { Context } from "../context/context";
import { useNavigate } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
export default function DrawerComponent({ component }) {
  const [open, setOpen] = useState(false);
  const { value, setValue } = useContext(Context);
  const [numberOfItems, setNumberOfItems] = useState(1);
  const [itemPrice, setItemPrice] = useState(0);
  const cartData = JSON.parse(localStorage.getItem("cart"));
  const navigate = useNavigate();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const RemoveItem = (dltItem) => {
    setItemPrice(0);
    const RemovedFilteredData = value?.filter((item) => {
      return item.id !== dltItem?.id;
    });
    if (RemovedFilteredData) {
      localStorage.setItem("cart", JSON.stringify([...RemovedFilteredData]));
      setValue([...RemovedFilteredData]);
      CostCalculation();
    }
  };
  const CostCalculation = () => {
    if (itemPrice === 0) {
      const a = value?.map((prod) => {
        setItemPrice(
          itemPrice +
            Math.ceil((prod?.price / 100) * (100 - parseInt(prod?.discount))) *
              prod?.NOI
        );
      });
    }
  };
  const AddSubtract = (item, operation) => {
    setItemPrice(0);
    if (operation === "Add") {
      setNumberOfItems(numberOfItems + 1);
      const updatedData = value?.map((prod) => {
        let uData;
        if (prod?.id === item?.id) {
          uData = { ...prod, NOI: item?.NOI + 1 };
        } else {
          uData = prod;
        }
        return uData;
      });
      localStorage.setItem("cart", JSON.stringify(updatedData));
      setValue(updatedData);
      CostCalculation();
    } else {
      setNumberOfItems(numberOfItems > 1 && numberOfItems - 1);
      const updatedData = value?.map((prod) => {
        let uData;
        if (prod?.id === item?.id) {
          uData = { ...prod, NOI: item?.NOI > 1 && item?.NOI - 1 };
        } else {
          uData = prod;
        }
        return uData;
      });
      localStorage.setItem("cart", JSON.stringify(updatedData));
      setValue(updatedData);
      CostCalculation();
    }
  };
  useEffect(() => {
    setItemPrice(0);
    CostCalculation();
  }, []);

  const DrawerList = (
    <Box
      sx={{
        width: { xs: 200, md: 350 },
        height: "100%",
        padding: "1rem",
      }}
      role="presentation"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "10vh" }}
      >
        <Text size="24">CART</Text>
        <Close sx={{ cursor: "pointer" }} onClick={toggleDrawer(false)} />
      </Stack>
      <List sx={{ height: {xs: "70vh", md: "75vh"}, overflowY: "auto" }}>
        <Divider />
        {value?.map((item, index) => {
          return (
            <ListItem key={index} disablePadding>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Box
                    sx={{
                      backgroundImage: `url(${item?.coverImage})`,
                      height: "100px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    {/* Optionally, you can include fallback content */}
                    {/* <img src={item?.coverImage} height="100px" alt={item?.alt} /> */}
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Stack
                    direction="column"
                    justifyContent="space-around"
                    sx={{ height: "100%" }}
                  >
                    <Text>Design No. {item?.id}</Text>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          sx={{
                            border: "1px solid black",
                            width: { xs: "55px", md: "100px" },
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            height: "2rem",
                          }}
                        >
                          <Text
                            size={24}
                            style={{
                              cursor: item?.NOI > 1 ? "pointer" : "not-allowed",
                              pointerEvents: item?.NOI <= 1 && "none",
                            }}
                            onClick={() => AddSubtract(item, "Subtract")}
                          >
                            -
                          </Text>
                          <Box
                            sx={{
                              border: "1px solid black",
                              height: "2rem",
                              display: "flex",
                              alignItems: "center",
                              padding: { xs: "0 0.2rem", md: "0 .5rem" },
                            }}
                          >
                            <Text>{item?.NOI}</Text>
                          </Box>
                          <Text
                            size={24}
                            onClick={() => AddSubtract(item, "Add")}
                            style={{ cursor: "pointer" }}
                          >
                            +
                          </Text>
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: { xs: "flex", md: "none" },
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: "#1976d2",
                            cursor: "pointer",
                          }}
                          onClick={() => RemoveItem(item)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: {xs: "none", md: "flex"},
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          onClick={() => RemoveItem(item)}
                          style={{ cursor: "pointer" }}
                          color="#1976d2"
                        >
                          Remove
                        </Text>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
      <Box sx={{ width: "100%" }}>
        <Button
          backgroundColor="black"
          color="white"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
          size={15}
          onClick={() => {
            navigate("/checkout");
            setOpen(false);
          }}
        >
          CHECKOUT
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Button backgroundColor="black" onClick={toggleDrawer(true)}>
        <IconButton aria-label="cart">
          <StyledBadge badgeContent={cartData?.length} color="secondary">
            <ShoppingCartOutlined style={{ color: "white" }} />
          </StyledBadge>
        </IconButton>
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>
    </Box>
  );
}
