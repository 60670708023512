import React from "react";
import Styles from "./footer.module.css";
import Text from "../Text/text";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={Styles.main_footer}>
      <footer className={Styles.footer}>
        <div className={Styles.footer_section_last}>
          <Text size={20} isBold color="white" style={{ margin: "0 0 1rem 0" }}>
            FOOTER MENU
          </Text>
          <Text
            color="white"
            onClick={() => navigate("/about-us")}
            style={{ cursor: "pointer" }}
          >
            About Us
          </Text>
          <Text
            color="white"
            onClick={() => navigate("/contact-us")}
            style={{ cursor: "pointer" }}
          >
            Contact Us
          </Text>
          <Text
            color="white"
            onClick={() => navigate("/privacy-policy")}
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </Text>
          <Text
            color="white"
            onClick={() => navigate("/refund-policy")}
            style={{ cursor: "pointer" }}
          >
            Refund Policy
          </Text>
          <Text
            color="white"
            onClick={() => navigate("/terms-of-use")}
            style={{ cursor: "pointer" }}
          >
            Terms of Service
          </Text>
        </div>
      </footer>
      <hr className={Styles.hr} />
      <div className={Styles.lastFooter}>
        <Text size={11} color="white">
          &copy; <a href="#">Awais Fabric</a>, All Right Reserved.
        </Text>
        <Text size={11} color="white">
          Designed By <a href="https://www.linkedin.com/in/zammad-ashraf-72482b19b/">Zammad Ashraf</a>
        </Text>
      </div>
    </div>
  );
};

export default Footer;
