import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import { ShoppingCartOutlined } from "@mui/icons-material";
import DrawerComponent from "../drawer/drawer";
import logo from "../../assets/images/logo.png";
import { notify } from "../notification/notification";
import { auth } from "../firebase/firebase";
import { signOut } from "firebase/auth";

const pages = [
  { title: "Add Product", path: "/admin/add-product" },
  { title: "Remove Product", path: "/admin/remove-product" },
];

const AdminHeader = ({ setFormType, isAddProduct }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const Logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/admin/login");
        notify("User logged out successfully.", "success");
      })
      .catch((error) => {
        notify(`Error logging out: ${error}`, "error");
      });
  };
  return (
    <AppBar sx={{ backgroundColor: "black" }} position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.title}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(page.path);
                  }}
                  style={{ height: "100%" }}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
              {isAddProduct && (
                <>
                  <MenuItem
                    onClick={() => setFormType("category")}
                    style={{ height: "100%" }}
                  >
                    <Typography textAlign="center">Category</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => setFormType("product")}
                    style={{ height: "100%" }}
                  >
                    <Typography textAlign="center">Product</Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => navigate(page.path)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.title}
              </Button>
            ))}
            {isAddProduct && (
              <>
                <Button
                  onClick={() => setFormType("category")}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Category
                </Button>
                <Button
                  onClick={() => setFormType("product")}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Product
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Logout">
              <Button
                onClick={Logout}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Logout
              </Button>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AdminHeader;
